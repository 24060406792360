<template>
  <div style="height: 100vh;">
    <div class="main">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>Login</span>
        </div>
        <el-form
          :model="ruleForm"
          status-icon
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="username" prop="pass">
            <el-input
              v-model="ruleForm.account"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="password" prop="pass">
            <el-input
              type="password"
              v-model="ruleForm.password"
              @change="submitForm('ruleForm')"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')"
              >Login</el-button
            >
          </el-form-item>
          <el-form-item>
            This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import { login } from "@/api/login";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      ruleForm: {
        password: "",
        account: "",
      },
      rules: {
        checkPass: [{trigger: "blur" }],
      },
    };
  },
  methods: {
    ...mapActions(["setUser", "setShowLogin", "setShoppingCart"]),
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            login(this.ruleForm).then((res) => {
              //console.log(res);
              if (res.code === 1) {
                this.$message({
                  message: "Login successful",
                  type: "success",
                });
                localStorage.setItem("token", res.data.token);
                localStorage.setItem("username",res.data.username);
                localStorage.setItem("nickname",res.data.nickname);
                this.$router.push("/");
                this.setUser(localStorage.getItem("token"));
                // this.$router.go(0)

              } else {
                // this.$message({
                //   message: res.msg,
                //   type: "error",
                // });
              }
            });
            // this.$router.push("/");
          } else {
            //console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      }
    }
};
</script>

<style lang="less" scoped>
.main {
  max-width: 1140px;
  margin: 0 auto;
  margin-top: 50px;
  /deep/ .el-form-item__content {
    line-height: 20px;
  }
}
</style>
